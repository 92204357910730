.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

#sidebar {
    min-width: 200px;
    max-width: 215px;
    transition: all 0.3s;
    background-color: #91969E;
    /* background-color:#cecdcf; */
    color: #fff;
    backdrop-filter: opacity(0.5);
    font-size: small;
    
    background-position: center;
    background-size: cover;
    border-right: 1px solid #6c757d;
    transition: .5s;
    position: relative;
    animation-name: hide;
    animation-duration: .5s;
}

#sidebar::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg,#ffffff 0%, #4468bb, 100%);   
    /* #4468bb, */
    content: '';
    opacity: .8;
    z-index: -1;
}

#minisidebar{
    min-width: 90px;
    max-width: 90px;
    transition: all 0.3s;
}

#sidebar.hide {
    /* display: none; */
   
  width: 0;
  min-width: 0;
  left: -100%;
    
}

#sidebar.show {
    /* display: none; */
   
  width: 200px;
  min-width: 200px;
    max-width: 215px;
  left: 0;
  animation-duration: .1s;
    
}

#minisidebar.active{
    display: none;
}

#sidebar .sidebar-header, #minisidebar .sidebar-header{
    padding: 20px;
}




#sidebar ul li a, #minisidebar ul li a {
    padding: 10px;
    font-size: 1em;
    display: block;
    text-decoration: none;
    padding-left: 1rem;
    color: #fff;
    white-space: nowrap;

}

#sidebar ul li a:hover,#minisidebar ul li a:hover {
    color: #fff;
    background-color: #3465ce5e;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background-color: #3465ceab;
    border-top:none !important;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}


ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}



/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content { 
    width: 100%;
    min-height: 100vh;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    /* #sidebar {
        margin-left: -215px;
    } */
    /* for mobile device hide sidebar on load */

    
    #sidebar.show{
        display:none
    }
    /* for mobile device show sidebar on load */
    #sidebar.hide{
        display:block;
        width: unset;
        min-width: unset;
        left: unset;
    }

    #sidebar.active {
        display: block;
        margin-left: 0;
    }
    #sidebarCollapse span {
        display: none;
    }
}
.minisidebar-header{
    width: 91px;
}




* {
  font-family: "Montserrat";
  font-size: 12px;
}

a{
  text-decoration: none !important;
}

body{
  background: #F4F5FA !important;
  
}

.maplabel{
  font-weight: bold;
  font-size: .75rem !important;
}

.fa-file-pdf.file-thumbnail{
  color: tomato;
}

.fa-file-word.file-thumbnail{
  color: #0d6efd;
}

.fa-file-excel.file-thumbnail{
  color: #198754;
}


footer {
  background-color: #647393;
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
}

footer a{
  text-decoration: none;
}

.header{
  background: none;
}
.header, .header a{
  color: #161e2c
}

.sidebar-header{
  color:#fff;
}

.btn-floating{
  border-radius: 50% !important;
}

.field-label{
  font-size: smaller;
}
.field-data{
  border-bottom: 1px solid #ccc;
  
}

.related-list-table a{
  text-decoration: none;
}

.btn-primary, .btn-info{
  background-color: #6C757D !important;
  color: #fff !important;
  border-color: #6C757D !important;
}

.login-form{
  background-color: #fff;
  width: 100%;
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  margin-top:5rem;
}

.login-form i{
  color: #f7981c;

}
.login-section{
  border-left: 1px solid #ccc;
}

.login-form img{
  width: 100%;
}

.login-form .vr{
  border-right: 1px solid #ccc;
}
.data-table a{
  text-decoration: none;
}

.data-table th{
  background-color: #91969E !important;
    color: #fff !important;
    border: 1px solid #91969E;
    font-weight: 400;
    text-transform: uppercase;
    white-space: nowrap;
}

.data-table{
  border: 1px solid #91969E;
  border-radius: 5px;
}

.view-form label{
  font-size: smaller;
  display: block;
  text-transform: uppercase;
  margin-top: 1rem;
}

.view-form-header{
  background-color: #546168;
  color: #fff;
  padding: .5rem;
  border-radius: 5px 5px 0 0;
}

.form-detail, .form-edit {
  border: 1px solid #ccc;
  padding-bottom: 1rem;
  background-color: rgba(255,255,255,.75);
}

.view-form span{
  display: block;
  border-bottom: 1px solid #ccc;
  
}

.fl{
  padding-left: 0px !important;
}
.fr{
  padding-right: 0px !important;
}

.data-table {
  font-size: smaller;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  

}
input[type=number].no-spinner::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
     margin: 0; 
}
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

.myTable {
  width: 800px;
}

.fc-event{
  cursor: pointer;
}
table.fc-col-header{
  background-color:#161e2c;
}

table.fc-col-header a{
  color: #fff !important;
}
th.fc-col-header-cell{
  padding: .5rem 0 .5rem 0 !important;
  /*border: 1px solid #161e2c !important;*/
}

.btn-outline-primary{
  border: 1px solid  !important;
    color: #333 !important;
    

}

.btn-outline-primary:hover{
  background-color: #91969E !important;
  color: #fff !important;
}

.btn-custom{
  border: 1px solid #1c3df7 !important;
    color: #fff !important;
    background: #1c3df7 !important;
}

/*
 * Progressbar
 */

 .bar {
  --d: 1rem;       /* arrow depth */
  --gap: 0.3rem;   /* arrow thickness, gap */

  display: flex;
  margin-right: var(--d);
}

.bar-step {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.6rem var(--d);
  margin-right: calc(var(--d) * -1 + var(--gap));
  background: #d3d7dd;
  color: #23468c;
  clip-path: polygon(
    0% 0%,
    calc(100% - var(--d)) 0%,
    100% 50%,
    calc(100% - var(--d)) 100%,
    0% 100%,
    var(--d) 50%
  );
}

.bar-step:first-child {
  clip-path: polygon(
    0% 0%,
    calc(100% - var(--d)) 0%,
    100% 50%,
    calc(100% - var(--d)) 100%,
    0% 100%
  );
}


.bar-step.active {
  background: #23468c;
  color: #fff;
}

.bar-step.completed {
  background: rgb(79,167,86);
  color: #fff;
}

.ibs-edit-form{
  background-color: #fff;
  margin-bottom: 1rem;
}
.card-header{
  background-color: #546168 !important;
  color: #fff !important;
}

.nav-link[aria-selected=false]{
  color: #fff !important;
}
.nav-link:hover, .nav-link:focus{
  color: #333 !important;
}
.nav-link{
  color: #fff;
}

.section-header{
  background-color: #91969E;
  color: #fff;
  padding: .5rem;
  margin-top: 1rem !important;
  text-transform: uppercase;
}
.form-select[required]{
  border-left: 4px solid red;
}

.Select[required] {
  border-left: 4px solid red;
}

  
input.form-control[required]{
  border-left: 4px solid red;
}

input.form-control[required]{
  border-left: 4px solid red;
}

textarea.form-control[required]{
  border-left: 4px solid red;
}

.form-select[required]{
  border-left: 4px solid red;
} 

.custom-select{
  border-left: 4px solid red;
}


#basic-navbar-nav.navbar-collapse {
  text-align: right !important
}

/* .bg-info {
  --bs-bg-opacity: 1; */
  /* background-color:#198754 !important; */
  /* background-color: red !important; */
/* } */
/* 
.bg-info .myclass {
  background-color: rgba(var(--bs-info-rgb),var(--bs-bg-opacity))!important;
} */


.bg-danger {
  background-color: red;
}

.bg-success {
  background-color: green;
}
.bg-warning {
  background-color: yellow;
}

.bg-default {
  background-color: rgb(50, 10, 229);
}

.bg-info {
  background-color: rgb(240, 137, 3);
}

.mobilecard{
  display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-right: 1rem;
}

.mobilecard span, .mobilecard a{
  width: 50%;
  padding-bottom: .7rem;
}


.circular-legend {
  display: flex;
  align-items: center;
}


/* You can add specific colors for your legend items */
.dot-label-1 { background-color: rgba(200, 76, 180, 1); }
.dot-label-2 { background-color: rgba(228, 161, 27, 1); }
.dot-label-3 { background-color: rgba(220, 76, 100, 1); }
/* Add more as needed */
.circular-legend {
  display: flex;
  align-items: center;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.legend-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
}

/* Add more styles as needed */
